.aipfrinciples-section {
    background: #010103;
    padding-top: 100px !important;
    padding-bottom: 200px!important    
}

.aipfrinciples-section h2 {
    color: #FFF;
    font-family: Montserrat;
    font-size: 40px;
    font-style: normal;
    font-weight: 300;
    line-height: 150%; /* 60px */
}

.aipfrinciples-section h2 span {
    color: #1EC337;
    font-family: Lora;
    font-size: 40px;
    font-style: italic;
    font-weight: 400;
    line-height: 150%;
}

.aipfrinciples-section .bl-green .text-box p {
    color: #FFF;
    font-family: Montserrat;
    font-size: 22px;
    font-style: normal;
    font-weight: 300;
    line-height: 33px;
    font-size: 18px;
    line-height: 150%; 
}
.bl-green:before {
    content: "";
    width: 1px;
    height: 100%;
    position: absolute;
    background: #1EEDA2;
    top: 0%;
    left: -30px;
}


.bl-green .text-box::before {
    content: "";
    width: 11px;
    height: 100%;
    position: absolute;
    background: #1EEDA2;
    top: 0%;
    left: -30px;
}


/* anima-box */
.aipfrinciples-section .anima-boxes {
    margin-top: 50px;
    padding-top: 0em;
    padding-bottom: 5em;
    --bs-gutter-x: 3em;
}

.aipfrinciples-section .anima-boxes .anima-box {
    display: flex;
    flex-direction: column;
    position: relative;
    transition: hover 1s;
}

.aipfrinciples-section .anima-boxes .anima-box .img-box img {
    width: 100%;
    border-radius: 20px 20px 0 0;
}

.aipfrinciples-section .anima-boxes .anima-box .text-box {
    position: absolute;
    cursor: pointer;
    width: 100%;
    bottom:-169px;
    height: 170px;
    bottom:-89px;
    height: 90px;
    padding: 25px 30px 15px;
    border-radius: 0 0 20px 20px;
    /* background: linear-gradient(108deg, rgba(255, 255, 255, 0.16) 0%, rgba(255, 255, 255, 0.18) 100%);
    backdrop-filter: blur(10px); */
    transition: all 1s;
}

.aipfrinciples-section .anima-boxes .anima-box .text-box.blue {
    background: #5CC04D;
}
.aipfrinciples-section .anima-boxes .anima-box .text-box.green {
    background: #0281DF;
}

.aipfrinciples-section .anima-boxes .anima-box .text-box.yellow {
    background: #BAAF3D;
}

.aipfrinciples-section .anima-boxes .anima-box .text-box h3 {
    color: #FFF;
    font-family: Montserrat;
    font-size: 26px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
}

.aipfrinciples-section .anima-boxes .anima-box .text-box h4 {
    color: #FFF;
    display: none;
    font-family: Montserrat;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
}

.aipfrinciples-section .anima-boxes .anima-box .text-box p {
    /* display: none; */
    color: #FFF;
    font-family: Montserrat;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    transform: translateY(20px);
    height: 0;
    visibility: hidden;
    opacity: 0;
    /* transition: all 2s; */
    transition: visibility 0s, opacity .5s ,display .8s,  transform 1s linear; 

}


.aipfrinciples-section .anima-boxes .anima-box:hover .text-box h4 {
    display: none;
}

.aipfrinciples-section .anima-boxes .anima-box:hover .text-box p {
    visibility: visible;
    opacity: 1;
    transform: translateY(0px);
    height: unset;
}

.aipfrinciples-section .anima-boxes .anima-box:hover .text-box {
    height: unset;
}

/* 
.aipfrinciples-section .anima-boxes .anima-box:hover .text-box.blue {
    height: unset;
    background: #0281DF;
}

.aipfrinciples-section .anima-boxes .anima-box:hover .text-box.green {
    height: unset;
    background: #31B6A8;
}

.aipfrinciples-section .anima-boxes .anima-box:hover .text-box.yellow {
    height: unset;
    background: #BAAF3D;
} */

.aipfrinciples-section h1 { 
    color: #FFF;
    text-align: center;
    font-family: Montserrat;
    font-size: 40px;
    font-style: normal;
    font-weight: 300;
    line-height: 150%;
}


.aipfrinciples-section .partners-5 {
    display: grid;
    grid-template-columns: auto auto auto auto auto auto;
    justify-content: space-between;
    align-items: center;
}

.aipfrinciples-section .partners-6 {
    display: grid;
    grid-template-columns: auto auto auto auto auto auto;
    justify-content: space-between;
    align-items: center;
}

.aipfrinciples-section .btn-row {
    padding-top: 3rem;
    padding-bottom: 3rem;
}

.aipfrinciples-section .partners-6.mobile ,
.aipfrinciples-section .partners-5.mobile,
.aipfrinciples-section .partners-6.tablet {
    display: none !important;
}


@media screen and (max-width: 767px) { 
    .aipfrinciples-section {
        padding-top: 50px !important;
        padding-bottom: 50px !important;
    }

    .aipfrinciples-section h2 {
        font-size: 22px;
    }

    .aipfrinciples-section h2 span {
        font-size: 24px;
    }

    .aipfrinciples-section .bl-green .text-box p {
        font-size: 16px;
        line-height: 24px;
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
    }

    .aipfrinciples-section .anima-boxes {
        padding-bottom: 1rem;
    }
    .aipfrinciples-section .anima-boxes .anima-box {
        margin-left: 1.5rem;
        margin-right: 1.5rem;
    }

    .aipfrinciples-section .anima-boxes .anima-box .text-box {
        position: inherit !important;
        bottom: 0px !important;
        height: unset !important;
    }

    .aipfrinciples-section .anima-boxes .anima-box .text-box h3 {
        font-size: 18px;
        line-height: 27px;
    }

    .aipfrinciples-section .anima-boxes .anima-box .text-box h4 {
        font-size: 14px;
        line-height: 21px;
        display: none;
    }
    
    .aipfrinciples-section .anima-boxes .anima-box .text-box p {
        visibility: visible;
        opacity: 1;
        transform: translateY(0px);
        height: unset;
        font-size: 12px;
    }
    .aipfrinciples-section .btn-row {
        padding: 0px;
    }

    .main-btn .main-btn {
        font-size: 14px;
        line-height: 14px;
    }
    
    .aipfrinciples-section h1 {
        font-size: 24px;
    }

 
    .aipfrinciples-section .partners-6.desktop ,
    .aipfrinciples-section .partners-5.desktop {
        display: none !important;
    }

    .aipfrinciples-section .partners-6.mobile {
        display: grid !important;
        grid-template-columns: auto auto auto;
        justify-content: space-between;
        align-items: left;
        text-align: left;
        gap: 20px 10px;
        padding-left: 15px;
        padding-right: 15px;
        margin: auto;
    }

    .aipfrinciples-section .partners-6.mobile img {
        width: auto;
        text-align: center;
        margin: auto;
        max-height: 30px;
    }

    
    .aipfrinciples-section .partners-5.mobile {
        display: grid !important;
        margin-top: 20px;
        grid-template-columns: auto auto auto;
        justify-content: center;
        align-items: center;
        text-align: center;
        gap: 40px;
    }

    .aipfrinciples-section .partners-5 img {
        width: auto;
        max-height: 35px;
        margin: auto;
    }
}


@media (min-width: 768px) and (max-width: 1024px) { 
    .aipfrinciples-section {
        padding-left: 3%;
        padding-right: 3%;
        padding-top: 30px !important;
        padding-bottom: 50px !important;
    }

    .aipfrinciples-section h2 {
        font-size: 22px;
    }

    .aipfrinciples-section h2 span {
        font-size: 24px;
    }

    .aipfrinciples-section .bl-green .text-box p {
        font-size: 16px;
        line-height: 24px;
    }

    .aipfrinciples-section .anima-boxes {
        padding-bottom: 1rem;
    }

    .aipfrinciples-section .anima-boxes .col-md-4 {
        width: 66.66663%;
        margin-left: auto;
        margin-right: auto;
    }

    .aipfrinciples-section .anima-boxes .anima-box {
        margin-left: 1.5rem;
        margin-right: 1.5rem;
    }

    .aipfrinciples-section .anima-boxes .anima-box .text-box {
        position: inherit !important;
        bottom: 0px !important;
        height: unset !important;
    }

    .aipfrinciples-section .anima-boxes .anima-box .text-box h3 {
        font-size: 18px;
        line-height: 27px;
    }

    .aipfrinciples-section .anima-boxes .anima-box .text-box h4 {
        font-size: 14px;
        line-height: 21px;
        display: none;
    }
    
    .aipfrinciples-section .anima-boxes .anima-box .text-box p {
        visibility: visible;
        opacity: 1;
        transform: translateY(0px);
        height: unset;
        font-size: 12px;
    }
    .aipfrinciples-section .btn-row {
        padding: 0px;
    }

    .main-btn .main-btn {
        font-size: 14px;
        line-height: 14px;
    }
    
    .aipfrinciples-section h1 {
        font-size: 24px;
    }

 
    .aipfrinciples-section .partners-6.desktop ,
    .aipfrinciples-section .partners-5.desktop {
        display: none !important;
    }

    .aipfrinciples-section .partners-6.tablet {
        display: grid !important;
        grid-template-columns: auto auto auto;
        justify-content: space-between;
        align-items: left;
        text-align: left;
        gap: 20px 10px;
        padding-left: 15px;
        padding-right: 15px;
        margin: auto;
    }

    .aipfrinciples-section .partners-6.tablet img {
        width: auto;
        text-align: center;
        margin: auto;
        max-height: unset;
    }

    
    /* .aipfrinciples-section .partners-5.mobile {
        display: grid !important;
        margin-top: 20px;
        grid-template-columns: auto auto auto;
        justify-content: center;
        align-items: center;
        text-align: center;
        gap: 40px;
    }

    .aipfrinciples-section .partners-5 img {
        width: auto;
        max-height: 35px;
        margin: auto;
    } */
}