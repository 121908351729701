.position form {
    margin-top: 50px;
}
.position form  label {
    width: 100%;
    display: block;
    color: #9F9AB6;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 162.023%;
}

.position form  input,
.position form  textarea {
    width: 100%;
    border: 0px;
    border-bottom: 1px solid #E1E1E2;
    outline: 0px;
}

.position form  textarea {
    height: 120px;
}

.position form  .row-btn {
    text-align: left;
}

.position form  button {
    color: #000;
    font-family: Montserrat;
    font-size: 16.272px;
    font-style: normal;
    font-weight: 500;
    line-height: 87.5%;
    display: inline-flex;
    padding: 19.323px 30.511px;
    justify-content: center;
    align-items: center;
    gap: 10.17px;
    border-radius: 5px;
    background: #1EEDA2;    
    border-color: transparent;
}

.nextchapter-section .right-side .position .form-success p {
    text-align: center;
}

@media screen and (max-width: 768px) { 

    .position form .row.mb-4 {
        margin-bottom: 0px !important;
    } 

    .position form .row label {
        margin-top: 1.5rem;
    }

}