header.header2 {
    /* background: url('../images/cloudapplication-bg.webp') no-repeat; */
    background-position: top center;
    background-size: cover;
    min-height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    background: rgba(0, 0, 0, 0.25);
}

/* 
header.cloudai-bg {
    background: url('../images/cloudapplication-bg.webp') no-repeat;
}

header.dataai-bg {
    background: url('../images/data-ai-bg.webp') no-repeat;
}

header.di-bg {
    background: url('../images/di-bg.webp') no-repeat;
}

header.ia-bg {
    background: url('../images/intelligent-automation-bg.webp') no-repeat;
}

header.contactus-bg {
    background: url('../images/contact-bg.webp') no-repeat;
}

header.header-whowr {
    background: url('../images/whoweare-bg.webp') no-repeat;
} */

/* header.ventures-bg {
    background: url('../images/ventures-bg.webp') no-repeat;
}

header.header-joinus {
    background: url('../images/joinus-bg.webp') no-repeat;
} 

header.insights-bg {
    background: url('../images/insights-bg.webp') no-repeat;
    background-size: cover;
}
*/

header video#background-video {
    width: 100%;
    position: absolute;
    object-fit: cover;
    left: 0;
    top: 0;
    height: 100vh;
    z-index: -1;
}


header.header-whowr .text-box,
header.header-whowr .text-box h1 {
    text-align: center;
}

header.di-bg .text-box,
header.di-bg .text-box h1{
    text-align: center;
    color:black
}

header.di-bg .text-box {
    margin-top: -20%;
}

header.header2 h1 {
    font-family: Montserrat;
    font-size: 51px;
    font-weight: 400;
    line-height: 72px;
    letter-spacing: 0em;
    text-align: left;
    color:#ffffff;
}

header.header2 h1 span {
    font-family: Lora;
    font-size: 56px;
    font-style: italic;
    font-weight: 400;
    line-height: 72px;
    letter-spacing: -0.02em;
    text-align: left;
    color:#1EEDA2;    
}

header.header2.terms {
    min-height: 90px;
    background: #000000;
}

@media screen and (max-width: 768px) { 

    header.header2 h1 {
        font-size: 28px;
        font-weight: 400;
        line-height: 34px;
        letter-spacing: 0em;
        text-align: center;
    }
    
    header.header2 h1 span {
        font-size: 32px;
        font-weight: 400;
        line-height: 41px;
        letter-spacing: 0em;
    }
 
    header.header2 .text-box {
        text-align: center;       
        width: 100%;
        height: 65vh;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center; 
    }    
 
    header.header2 .text-box .main-btn {
        max-width: 200px;
    }
    
    header.header2.di-bg .text-box h1 {
        color:#ffffff;
    }

    header.header2.terms {
        min-height: 74px;
    }
    header.header2.terms .text-box {
        max-height: 74px;
        height: unset;
    }
}


@media (min-width: 768px) and (max-width: 1024px) { 

    header.header2.terms {
        min-height: 74px;
    }
    
    header.header2.terms .text-box {
        max-height: 74px;
        height: unset;
    }

    header.header2 h1 {
        font-size: 28px;
        font-weight: 400;
        line-height: 34px;
        letter-spacing: 0em;
        text-align: center;
    }
    
    header.header2 h1 span {
        font-size: 32px;
        font-weight: 400;
        line-height: 41px;
        letter-spacing: 0em;
    }
 
    header.header2 .text-box {
        text-align: center;       
        width: 100%;
        height: 65vh;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center; 
    }    
 
    header.header2 .text-box .main-btn {
        max-width: 200px;
    }
    
    header.header2.di-bg .text-box h1 {
        color:#ffffff;
    }

}