.section-homecontact {
    padding-top:100px;
    padding-bottom:100px;
    background:url('../images/home-contact-bg.webp') no-repeat;
    background-size: cover;
}

.section-homecontact.contactbg {
    background:url('../images/contactbg.webp') no-repeat;
    background-size: cover;
}

.section-homecontact .bl-green h1 {
    color: #000;
    font-family: Montserrat;
    font-size: 40px;
    font-style: normal;
    font-weight: 300;
    line-height: 150%; 
}

.section-homecontact .bl-green h1 span {
    color: #1EC337;
    font-family: Lora;
    font-size: 40px;
    font-style: italic;
    font-weight: 400;
    line-height: 150%;
}

.section-homecontact .bl-green p {
    color: #000;
    font-family: Montserrat;
    font-size: 22px;
    font-style: normal;
    font-weight: 300;
    line-height: 150%;
}

.section-homecontact .white-bg {
    background: white;
    padding: 60px;
}

.section-homecontact img.map {
    width: 100%;
    max-width: 649px;
}

.section-homecontact label {
    width: 100%;
    display: block;
    color: #9F9AB6;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 162.023%;
}

.section-homecontact label span {
    color: red;
}

.section-homecontact input,
.section-homecontact textarea {
    width: 100%;
    border: 0px;
    border-bottom: 1px solid #E1E1E2;
    outline: 0px;
}

.section-homecontact textarea {
    min-height: 100px;
}

.section-homecontact .row-btn {
    text-align: left;
}

.section-homecontact button {
    color: #000;
    font-family: Montserrat;
    font-size: 16.272px;
    font-style: normal;
    font-weight: 500;
    line-height: 87.5%;
    display: inline-flex;
    padding: 19.323px 30.511px;
    justify-content: center;
    align-items: center;
    gap: 10.17px;
    border-radius: 5px;
    background: #1EEDA2;    
    border-color: transparent;
}


@media screen and (max-width: 767px) {  
    .section-homecontact {
        padding-top: 50px;
        padding-bottom: 50px;
    }
    .section-homecontact .white-bg {
        margin: 25px 20px 0px;
        padding: 45px;
    }
    .section-homecontact .bl-green.py-5 {
        padding-top: 0.5rem !important;
        padding-bottom: 0.5rem !important;
    }

    .section-homecontact .bl-green h1,
    .section-homecontact .bl-green h1 span {
        font-size: 24px;
        line-height: 36px;
    }
    .section-homecontact .bl-green p {
        font-size: 14px;
        line-height: 21px;
    }

    .section-homecontact img.title {
        width : 40%;
    }

    .section-homecontact img.map {
        width: 100%;
        max-width: unset;
    }

    .section-homecontact label {
        font-size: 12px;
        font-weight: 400;
        line-height: 19px;
    }
    
    .section-homecontact .row-btn {
        text-align: center;
    }

    .section-homecontact button {
        font-size: 14px;
        line-height: 14px;
        letter-spacing: 0em;
    }
}

@media (min-width: 768px) and (max-width: 1024px) { 
    .section-homecontact {
        padding-top: 50px;
        padding-bottom: 50px;
    }
    .section-homecontact .white-bg {
        margin: 25px 20px 0px;
        padding: 45px;
    }
    .section-homecontact .bl-green.py-5 {
        padding-top: 0.5rem !important;
        padding-bottom: 0.5rem !important;
    }

    .section-homecontact .bl-green h1,
    .section-homecontact .bl-green h1 span {
        font-size: 24px;
        line-height: 36px;
    }
    .section-homecontact .bl-green p {
        font-size: 14px;
        line-height: 21px;
    }

    .section-homecontact img.title {
        width : 40%;
    }

    .section-homecontact img.map {
        width: 100%;
        max-width: unset;
    }

    .section-homecontact label {
        font-size: 12px;
        font-weight: 400;
        line-height: 19px;
    }
    
    .section-homecontact .row-btn {
        text-align: center;
    }

    .section-homecontact button {
        font-size: 14px;
        line-height: 14px;
        letter-spacing: 0em;
    }
    
    .section-homecontact .col-md-7,
    .section-homecontact .col-md-5 {
        width: 100%;
    }
}