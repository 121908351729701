.fundthesis-section {
    padding: 100px 0px;
}

.fundthesis-section .main-box {
    display: flex;
    flex-direction: column;
    gap: 36px;
}

.fundthesis-section .main-box h1 {
    color: #000;
    text-align: center;
    font-family: Montserrat;
    font-size: 40px;
    font-style: normal;
    font-weight: 300;
    line-height: 150%; /* 60px */
}

.fundthesis-section .main-box h1 span {
    color: #1EC337;
    font-family: Lora;
    font-size: 40px;
    font-style: italic;
    font-weight: 400;
    line-height: 150%;
}


/* fundthesis-section */
.fundthesis-section {
    padding-top: 4rem!important;
    padding-bottom: 2rem!important    
}

.fundthesis-section .row {
    justify-content: center;
    align-items: center;
    padding-top: 1.5em;
    padding-bottom: 1.5em;
}


.text-right {
    text-align: right !important;
}

.fundthesis-section .bl-green h2 {
    color: #1A202C;
    font-family: Montserrat;
    font-size: 40px;
    font-style: normal;
    font-weight: 300;
    line-height: 150%; 
}

.fundthesis-section .bl-green h2 span { 
    color: #1EC337;
    font-family: Lora;
    font-size: 40px;
    font-style: italic;
    font-weight: 400;
    line-height: 150%;
}


.fundthesis-section  .bl-green {
    position: relative;
    padding-top: 1em;
    padding-bottom: 1em;
}

.fundthesis-section  .bl-green .text-box.pe-5 {
    position: relative;
    padding-right: 1rem !important;
}

.bl-green:before {
    content: "";
    width: 1px;
    height: 100%;
    position: absolute;
    background: #1EEDA2;
    top: 0%;
    left: -30px;
}


.bl-green .text-box::before {
    content: "";
    width: 11px;
    height: 100%;
    position: absolute;
    background: #1EEDA2;
    top: 0%;
    left: -30px;
}

.fundthesis-section h3 {
    color: #000;
    font-family: Montserrat;
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
}

.fundthesis-section p {
    color: #000;
    font-family: Montserrat;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; 
}

.fundthesis-section .bg-1 {
    background: url('../images/Technology-themes.webp');
    background-repeat: no-repeat;
    background-position: top left;
    background-size: contain;
    background-attachment: fixed;
    /* padding: 499px 272px; */
    height:100vh;
    width: 100%;
}

.fundthesis-section .bg-2 {
    background: url('../images/Business-themes.webp');
    background-repeat: no-repeat;
    background-position: top right;
    background-size: contain;
    background-attachment: fixed;
    /* padding: 499px 272px; */
    height:100vh;
    
    width: 100%;
}

.fundthesis-section .text-side h3 {
    font-family: Montserrat;
    font-size: 22px;
    font-weight: 500;
    line-height: 33px;
    letter-spacing: 0em;
    text-align: left;
    color: #000000;
}

.fundthesis-section .text-side h4 {
    font-family: Lora;
    font-size: 20px;
    font-style: italic;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
    color: #1EC337;
}


.fundthesis-section .text-side p {
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
}

.fundthesis-section .text-side ul  {
    padding-left: 20px;
    margin-top: 20px;
    list-style-image: url('../images/Ornament44.svg');    
}

.fundthesis-section .text-side ul li {
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: #000000;
    margin-bottom: 10px;
}


.fundthesis-section .text-side ul li::before {
    content: ""; 
    width: 11.52px;
    height: 11.52px;
    top: 353.14px;
    left: 95.14px;
    color:#010103;
  }

  .fundthesis-section .text-side h5 {
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: #000000;
  }

  .fundthesis-section .text-side p.inline-list {
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 600;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
    color: #000000;    
  }



  .fundthesis-section h3 {
    color: #000;
    font-family: Montserrat;
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
}

.fundthesis-section h4 {
    color: #1EC337;
    font-family: Lora;
    font-size: 20px;
    font-style: italic;
    font-weight: 400;
    line-height: 150%;
}

.fundthesis-section p {
    color: #000;
    font-family: Montserrat;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
}

  .fundthesis-section .slide-btn {
    display: inline-flex;
    padding: 19.323px 30.511px;
    justify-content: center;
    align-items: center;
    gap: 10.17px;
    border-radius: 5px;
    background: #1EEDA2;
    text-decoration: none;
    color: #000;
    font-family: Montserrat;
    font-size: 16.272px;
    font-style: normal;
    font-weight: 500;
    line-height: 87.5%;
}




@media screen and (max-width: 767px) {
    
    .fundthesis-section {
        padding-top: 0px !important;
    }

    .fundthesis-section .bl-green h2,
    .fundthesis-section .bl-green h2 span {
        font-size: 22px;
    }

    .fundthesis-section .bl-green .text-box p {
        font-size: 16px;
        line-height: 24px;
    }


    .fundthesis-section .main-box h1 {
        font-family: Montserrat;
        font-size: 14px;
        font-weight: 600;
        line-height: 21px;
        letter-spacing: 0em;
        text-align: left;        
    }

    .fundthesis-section .main-box h1 span {
        font-family: Lora;
        font-size: 14px;
        font-style: italic;
        font-weight: 400;
        line-height: 21px;
        letter-spacing: 0em;
        text-align: left;        
    }

    .fundthesis-section .text-side p {
        font-family: Montserrat;
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
        letter-spacing: 0em;
        text-align: left;
    }

    .fundthesis-section .text-side ul  {
        list-style-image: url('../images/Ornament-mobile.png');    
    }


    .fundthesis-section .text-side ul li {
        font-family: Montserrat;
        font-size: 12px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: left;
    }

    .fundthesis-section .text-side h5 {
        font-family: Montserrat;
        font-size: 12px;
        font-weight: 600;
        line-height: 18px;
        letter-spacing: 0em;
        text-align: left;
    }

    .fundthesis-section .text-side p.inline-list {
        font-family: Montserrat;
        font-size: 12px;
        font-weight: 600;
        line-height: 18px;
        letter-spacing: 0em;
        text-align: left;
    }

    .fundthesis-section .bg-img {
        /* background: url('../images/Cloud-Modernization.webp'); */
        background-repeat: no-repeat;
        background-position: top center;
        background-size: cover;
        background-attachment: unset;
        height:auto;    
        width: 100%;
        position: relative;
        margin-bottom: 150px;
    }

    .fundthesis-section .bg-img.bg-5 {
        margin-bottom: 100px;
    }

    .fundthesis-section .text-side {
        /* position: absolute; */
        background: #ffffff;
        width: 85%;
        margin: auto;
        /* transform: translateY(100px); */
        padding: 5%;
    }

    .fundthesis-section  .main-btn {
        font-family: Montserrat;
        font-size: 14px;
        font-weight: 500;
        line-height: 14px;
        letter-spacing: 0em;
        text-align: left;
        margin-top: -15px;
    }


    .fundthesis-section img {
        width: 50%;
        text-align: center;
    }
}


@media (min-width: 768px) and (max-width: 1024px) {
    
    .fundthesis-section {
        padding-top: 0px !important;
    }

    .fundthesis-section .bl-green h2,
    .fundthesis-section .bl-green h2 span {
        font-size: 22px;
    }

    .fundthesis-section .bl-green .text-box p {
        font-size: 16px;
        line-height: 24px;
    }


    .fundthesis-section .main-box h1 {
        font-family: Montserrat;
        font-size: 14px;
        font-weight: 600;
        line-height: 21px;
        letter-spacing: 0em;
        text-align: left;        
    }

    .fundthesis-section .main-box h1 span {
        font-family: Lora;
        font-size: 14px;
        font-style: italic;
        font-weight: 400;
        line-height: 21px;
        letter-spacing: 0em;
        text-align: left;        
    }

    .fundthesis-section .text-side p {
        font-family: Montserrat;
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
        letter-spacing: 0em;
        text-align: left;
    }

    .fundthesis-section .text-side ul  {
        list-style-image: url('../images/Ornament-mobile.png');    
    }


    .fundthesis-section .text-side ul li {
        font-family: Montserrat;
        font-size: 12px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: left;
    }

    .fundthesis-section .text-side h5 {
        font-family: Montserrat;
        font-size: 12px;
        font-weight: 600;
        line-height: 18px;
        letter-spacing: 0em;
        text-align: left;
    }

    .fundthesis-section .text-side p.inline-list {
        font-family: Montserrat;
        font-size: 12px;
        font-weight: 600;
        line-height: 18px;
        letter-spacing: 0em;
        text-align: left;
    }

    .fundthesis-section .bg-img {
        /* background: url('../images/Cloud-Modernization.webp'); */
        background-repeat: no-repeat;
        background-position: top center;
        background-size: cover;
        background-attachment: unset;
        height:auto;    
        width: 100%;
        position: relative;
        margin-bottom: 150px;
    }

    .fundthesis-section .bg-img.bg-5 {
        margin-bottom: 100px;
    }

    .fundthesis-section .text-side {
        /* position: absolute; */
        background: #ffffff;
        /* width: 85%; */
        margin: auto;
        /* transform: translateY(100px); */
        padding: 5%;
    }

    .fundthesis-section  .main-btn {
        font-family: Montserrat;
        font-size: 14px;
        font-weight: 500;
        line-height: 14px;
        letter-spacing: 0em;
        text-align: left;
        margin-top: -15px;
    }
}
