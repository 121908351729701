.ppolicy-section {
    padding-top: 4rem!important;
    padding-bottom: 2rem!important    
}


.ppolicy-section .bl-green:before {
    height: 50%;
    top:8%;
}

.ppolicy-section .bl-green .text-box::before {
    height: 80%;
    top: 10%;
}

.ppolicy-section .bl-green  .text-box h2 {
    color: #1A202C;
    font-family: Montserrat;
    font-size: 40px;
    font-style: normal;
    font-weight: 300;
    line-height: 150%; 
}

.ppolicy-section .bl-green .text-box h2 span { 
    color: #1EC337;
    font-family: Lora;
    font-size: 40px;
    font-style: italic;
    font-weight: 400;
}

.ppolicy-section .bl-green p {
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 400;
    line-height: 33px;
    letter-spacing: 0em;
    text-align: left;
    color: #121416;    
}

.ppolicy-section .left-side {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 30px;
}


.ppolicy-section .left-side .tabs {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 10px;
    /* box-shadow: -1px 0px 0px 0px #E5E5E5; */
    border-left: 1px solid #E5E5E5;
    overflow-x: hidden;
}

.ppolicy-section .left-side .tabs .tablinks {
    color: #838383;
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: left;    
    cursor: pointer;
    padding-left: 20px;
    margin-bottom: 10px;
    white-space: nowrap;
}

.ppolicy-section .left-side .tabs .tablinks.active {
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: left;
    color: #121416;    
    border-left: 4px solid #0281DF; 
    /* box-shadow: -4px 0px 0px 0px #0281DF; */
}

.ppolicy-section .right-side  .tabcontent {
    display: none;
    transition:all .5s;
}

.ppolicy-section .right-side  .tabcontent.active {
    display: block;
 }

.ppolicy-section .right-side .tabcontent h2 {
    font-family: Montserrat;
    font-size: 40px;
    font-weight: 400;
    line-height: 49px;
    letter-spacing: 0px;
    text-align: left;
    margin-bottom: 1em;
}


.ppolicy-section .right-side .tabcontent h3 {
    font-family: Montserrat;
    font-size: 26px;
    font-weight: 500;
    line-height: 33px;
    letter-spacing: 0em;
    text-align: left;
    margin-bottom: 1em;          
}

.ppolicy-section .right-side .tabcontent h4 {
    font-family: Montserrat;
    font-size: 26px;
    font-weight: 500;
    line-height: 33px;
    letter-spacing: 0em;
    text-align: left;
}

.ppolicy-section .right-side p {
    font-family: Montserrat;
    font-size: 18px;
    font-weight: 400;
    line-height: 33px;
    letter-spacing: 0em;
    text-align: left;
    margin-bottom: 20px;
}


.ppolicy-section .right-side ul.disc-img  {
    list-style-image: url('../images/Ornament44.svg');    
}


.ppolicy-section .right-side ul,
.ppolicy-section .right-side ol {
    margin-top: -16px;
} 

.ppolicy-section .right-side ul li {
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    margin: 10px 0px;
}

.ppolicy-section .right-side ol li {
    font-family: Montserrat;
    font-size: 18px;
    font-weight: 400;
    line-height: 33px;
    letter-spacing: 0em;
    text-align: left;
    margin: 10px 0px;
}


@media screen and (max-width: 768px) { 

    .ppolicy-section {
        padding-top: 0px !important;
    }
    
    .ppolicy-section  .right-side {
        margin-top: 50px;
    }

    .ppolicy-section .bl-green .text-box h2 {
        font-size: 32px;
        line-height: 40px;
    }


    .ppolicy-section  .right-side .tabcontent h2 {
        font-size: 32px;
        font-weight: 400;
        line-height: 40px;
    }
    
    
    .ppolicy-section  .right-side .tabcontent h3 {
        font-size: 22px;
        font-weight: 500;
        line-height: 30px;
    }
    
    
}