.slider-section {
    padding: 0px;
    margin: 0px;
    width: 100vw;
}

.home-slider .swiper-slide {
    width: 100vw;
    min-height: 100vh;
    background-position: top center;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.home-slider .swiper-pagination {
    bottom: 60px;
}

.home-slider .swiper-pagination span.swiper-pagination-bullet {
    background: #9A9A9A;
    width: 100px;
    height: 2px;
    border-radius: 0;
    margin: 0px 10px !important;
    position: relative;
}

.home-slider .swiper-pagination span.swiper-pagination-bullet::after {
	content: "";
    background: #1EEDA2;
	position: absolute;
	bottom: 0;
	left: 0;
	height: 2px;
	width: 0;
    /* animation-delay: 1s; */
	transition: 10s ease-in-out;
}


.home-slider .swiper-pagination span.swiper-pagination-bullet.swiper-pagination-bullet-active::after {
    width: 100%;
}

/* .slide1 {
    background: url('../images/banner1.webp');
}

.slide2 {
    background: url('../images/banner2.webp');
}

.slide3 {
    background: url('../images/banner3.webp');
}

.slide4 {
    background: url('../images/banner4.webp');
}

.slide5 {
    background: url('../images/banner5.webp');
} */

.home-slider .swiper-slide {
    position: relative;
}

.home-slider .swiper-slide video.background-video {
    width: 100%;
    position: absolute;
    object-fit: cover;
    left: 0;
    top: 0;
    height: 100vh;
    z-index: -1;
}

.home-slider .swiper-slide .text-box .heading {
    color: #FFF;
    font-family: Montserrat;
    font-size: 50.851px;
    font-style: normal;
    font-weight: 400;
    line-height: 142%;
}


.home-slider .swiper-slide .text-box .heading span{
    color: #1EEDA2;
    font-family: Lora;
    font-size: 56px;
    font-style: italic;
    font-weight: 400;
    line-height: 72.209px;
    letter-spacing: -1.12px;
}

.home-slider .swiper-slide .text-box a.slide-btn {
    color: #000;
    font-family: Montserrat;
    font-size: 16.272px;
    font-style: normal;
    font-weight: 500;
    line-height: 87.5%; 
    margin-top: 20px;
    /* display: inline-flex; */
    padding: 19.323px 30.511px;
    justify-content: center;
    align-items: center;
    gap: 10.17px;

    border-radius: 5px;
    background: #1EEDA2;
    text-decoration: none;
}

.home-slider .swiper-slide .text-box .text {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 500;
    font-size: 16.0886px;
    color: white;
    line-height: 22px;
    transition: all .2s;
}



@media screen and (max-width: 767px) {  

.home-slider .swiper-slide .text-box .heading {
    font-size: 28px;
    line-height: 34px;
    font-size: 25px;
    line-height: 30px;
    font-weight: 400;
    letter-spacing: 0em;
}


.home-slider .swiper-slide .text-box .heading span{
    font-family: Lora;
    font-size: 32px;
    line-height: 41px;
    font-size: 28px;
    line-height: 32px;
    font-style: italic;
    font-weight: 400;
    letter-spacing: -0.02em;
    text-align: center;
}


.home-slider .swiper-slide .text-box a.slide-btn {
    font-size: 14px;
    font-weight: 500;
    line-height: 14px;
    letter-spacing: 0em;
}

.home-slider .swiper-pagination span.swiper-pagination-bullet {
    background: #9A9A9A;
    width: 20px;
    height: 2px;
    border-radius: 0;
    margin: 0px 5px !important;
    position: relative;
}

.home-slider .swiper-slide,
.home-slider .swiper-slide.slide4,
.home-slider .swiper-slide.slide5  {
    justify-content: flex-start !important;
    text-align: center;
    padding-top: 120px;
}

/* .home-slider .swiper-slide.slide2 {
    justify-content: center !important;
} */


.home-slider .swiper-slide .text-box {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 65vh;
}

.home-slider .swiper-slide .text-box .slide-btn {
    max-width: 130px;
}

}

@media (min-width: 768px) and (max-width: 1024px) { 
    .home-slider .swiper-slide .col-md-8,
    .home-slider .swiper-slide .col-md-12 {
        width: 75%;
        margin: auto;
    }

    .home-slider .swiper-slide .text-box .heading {
        font-size: 36px;
        line-height: 42px;
        font-weight: 400;
        letter-spacing: 0em;
    }
    
    
    .home-slider .swiper-slide .text-box .heading span{
        font-family: Lora;
        font-size: 40px;
        line-height: 46px;
        font-style: italic;
        font-weight: 400;
        letter-spacing: -0.02em;
        text-align: center;
    }
    
    
    .home-slider .swiper-slide .text-box a.slide-btn {
        font-size: 16px;
        font-weight: 500;
        line-height: 14px;
        letter-spacing: 0em;
    }
    
    .home-slider .swiper-pagination span.swiper-pagination-bullet {
        background: #9A9A9A;
        width: 30px;
        height: 2px;
        border-radius: 0;
        margin: 0px 5px !important;
        position: relative;
    }
    
    .home-slider .swiper-slide,
    .home-slider .swiper-slide.slide4,
    .home-slider .swiper-slide.slide5  {
        justify-content: flex-start !important;
        text-align: center;
        padding-top: 120px;
    }
    
    /* .home-slider .swiper-slide.slide2 {
        justify-content: center !important;
    } */

    
    .home-slider .swiper-slide .text-box {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        height: 70vh;
    }
    
    .home-slider .swiper-slide .text-box .slide-btn {
        max-width: 150px;
    }
    
}


@media screen and (max-height: 450px) {  
    .home-slider .swiper-slide,
    .home-slider .swiper-slide.slide4,
    .home-slider .swiper-slide.slide5  {
        padding-top: 80px !important ;
    }

    .home-slider .swiper-slide .text-box {
        height: 60vh;
    }

    .home-slider .swiper-slide.slide4 .text-box,
    .home-slider .swiper-slide.slide2 .text-box {
        height: 70vh;
    }
}