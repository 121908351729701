footer {
    padding-top: 100px;
    background: #010103;
}

footer .cp p {
    color: #FFF;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 214.286%; 
    margin-top: 1em;
}

footer h4 {
    color: #1EEDA2;
    font-family: Montserrat;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.09px;
    margin-bottom: 1em;
}

footer .links ul {
    list-style: none;
    padding-left: 0px;
}

footer .links ul li a {
    color: #FFF;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 236.3%;
    text-decoration: none;
}


footer .contactus p {
    color: #FFF;
    font-family: Montserrat;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 166.667%; 
}

footer .contactus a {
    color: #FFF;
    font-family: Montserrat;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 166.667%;
    text-decoration: none;
}

footer .copyright {
    border-top: 1px solid #1EEDA2;
    padding-top:35px;
    padding-bottom: 35px;
    text-align: center;
    color: #FFF;

    /* Footer/Footer-sm */
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 71.429%;
    letter-spacing: -0.07px;
}


@media screen and (max-width: 767px) {
    
    footer {
        padding-top: 50px;
    }

    footer .row {
        flex-wrap: nowrap;
        padding: 0px 25px;
    }

    .col-md-3.cp,
    .col-md-2.offset-1.links {
        width: 50%;
    }
     

    footer .cp p {
        font-size: 12px;
        line-height: 22px;
        letter-spacing: 0em;
        text-align: left;
        margin-top: 2rem;
    }

    footer .contactus a {
        font-size: 12px;
        font-weight: 600;
        line-height: 10px;
        letter-spacing: 0em;
        text-align: left;
    }

    footer h4 {
        font-family: Montserrat;
        font-size: 14px;
        font-weight: 700;
        line-height: 17px;
        letter-spacing: -0.005em;
        margin-bottom: 2rem;
    }

    footer .links ul li a {
        font-size: 12px;
        font-weight: 400;
        line-height: 30px;
        letter-spacing: 0em;
        text-align: left;
    }

    footer .col-md-3.offset-9 {
        margin-left: 0px;
        padding: 0px;
    }

    footer .copyright {
        font-family: Inter;
        font-size: 11px;
        font-weight: 400;
        line-height: 10px;
        letter-spacing: -0.005em;
        text-align: center;
        color: #8F8F8F;
    }

}



@media (min-width: 768px) and (max-width: 1024px) { 

    footer .col-md-2.offset-1.links {
        width: 25% !important;
    }
}