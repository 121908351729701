.section-WwerContact {
    padding-top:100px;
    padding-bottom:100px;
    background:url('../images/home-contact-bg.webp') no-repeat;
    background-size: cover;
}

.section-WwerContact.contactbg {
    background:url('../images/contactbg.webp') no-repeat;
    background-size: cover;
}

.section-WwerContact .bl-green h1 {
    color: #000;
    font-family: Montserrat;
    font-size: 40px;
    font-style: normal;
    font-weight: 300;
    line-height: 150%; 
}

.section-WwerContact .bl-green h1 span {
    color: #1EC337;
    font-family: Lora;
    font-size: 40px;
    font-style: italic;
    font-weight: 400;
    line-height: 150%;
}

.section-WwerContact .bl-green p {
    color: #000;
    font-family: Montserrat;
    font-size: 22px;
    font-style: normal;
    font-weight: 300;
    line-height: 150%;
}

.section-WwerContact .white-bg {
    background: white;
    padding: 50px;
}

.section-WwerContact label {
    width: 100%;
    display: block;
    color: #9F9AB6;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 162.023%;
}

.section-WwerContact label span {
    color: red;
}


.section-WwerContact input {
    width: 100%;
    border: 0px;
    border-bottom: 1px solid #E1E1E2;
    outline: 0px;
}

.section-WwerContact button {
    color: #000;
    font-family: Montserrat;
    font-size: 16.272px;
    font-style: normal;
    font-weight: 500;
    line-height: 87.5%;
    display: inline-flex;
    padding: 19.323px 30.511px;
    justify-content: center;
    align-items: center;
    gap: 10.17px;
    border-radius: 5px;
    background: #1EEDA2;    
    border-color: transparent;
}

.section-WwerContact p.form-error {
    color:red;
}

@media screen and (max-width: 767px) { 

    .section-WwerContact {
        padding:50px 0px;
    }
    .section-WwerContact .bl-green h1 {
        font-size: 24px;
        font-weight: 300;
        line-height: 36px;
        letter-spacing: 0em;
    }

    .section-WwerContact .bl-green h1 span {
        font-size: 24px;
        font-weight: 400;
        line-height: 36px;
        letter-spacing: 0em;
    }

    .section-WwerContact .bl-green p {
        font-family: Montserrat;
        font-size: 14px;
        font-weight: 300;
        line-height: 21px;
        letter-spacing: 0em;
        text-align: left;
    }

    .section-WwerContact form .row.mb-4 {
        margin-bottom: 0px !important;
    } 

    .section-WwerContact form .row label {
        margin-top: 1.5rem;
    }
    .section-WwerContact .white-bg {
        background: white;
        padding: 35px;
        margin: 30px 25px 0px;
        border-radius: 10px;
    }
}


@media (min-width: 768px) and (max-width: 1024px) {

    .section-WwerContact {
        padding:50px 0px;
    }
    .section-WwerContact .bl-green h1 {
        font-size: 24px;
        font-weight: 300;
        line-height: 36px;
        letter-spacing: 0em;
    }

    .section-WwerContact .bl-green h1 span {
        font-size: 24px;
        font-weight: 400;
        line-height: 36px;
        letter-spacing: 0em;
    }

    .section-WwerContact .bl-green p {
        font-family: Montserrat;
        font-size: 14px;
        font-weight: 300;
        line-height: 21px;
        letter-spacing: 0em;
        text-align: left;
    }

    .section-WwerContact form .row.mb-4 {
        margin-bottom: 0px !important;
    } 

    .section-WwerContact form .row label {
        margin-top: 1.5rem;
    }
    .section-WwerContact .white-bg {
        background: white;
        padding: 35px;
        margin: 30px 25px 0px;
        border-radius: 10px;
    }
}