
.about-ca-section  {
    padding-top: 50px;
    padding-bottom: 50px;
}

.about-ca-section  img {
    width: 100%;
}

.about-ca-section .text-box p span {
font-family: Lora;
font-size: 20px;
font-style: italic;
font-weight: 500;
line-height: 30px;
letter-spacing: 0em;
text-align: left;
color: #1EC337;
}

.about-ca-section .text-box p {
font-family: Montserrat;
font-size: 18px;
font-weight: 400;
line-height: 27px;
letter-spacing: 0em;
text-align: left;
color: #1A202C;
}



@media screen and (max-width: 767px) {  

    .about-ca-section {
        padding-top: 50px;
        padding-bottom: 50px;
    }
    
    .about-ca-section.about-venture img {
        width: 65%;
    }

    .about-ca-section .text-box p ,
    .about-ca-section .text-box p span {
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
    }
   
    .about-ca-section .bl-green {
        padding-left: 3rem !important;
        padding-right: 1rem !important;
    }

}