.venture-disruptors-section {
    padding-top: 0px;
    padding-bottom: 50px;
 }
 
 .venture-disruptors-section img {
     width: 100%;
     width: auto;
     max-height: 100px;
 }
 
 
 .venture-disruptors-section h1 {
     color: #000;
     text-align: center;
     font-family: Montserrat;
     font-size: 40px;
     font-style: normal;
     font-weight: 300;
     line-height: 150%; /* 60px */
 }
 
 .venture-disruptors-section h1 span {
     color: #1EC337;
     font-family: Lora;
     font-size: 40px;
     font-style: italic;
     font-weight: 400;
     line-height: 150%;
 }
 
 
 .venture-disruptors-section .venture-brands-logos {
    display: grid;
    padding: 0px 80px;
    grid-template-columns: auto auto auto auto;
    gap: 60px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.venture-disruptors-section .venture-brands-logos img {
    margin: auto;
}

.venture-disruptors-section .venture-brands-logos img:last-child{
    max-height: 75px;
}

.venture-disruptors-section .venture-brands-logos div:last-child img {
    height: 60px;
}


@media screen and (max-width: 767px) { 
     
     .venture-disruptors-section {
         padding-top: 50px;
         padding-bottom: 50px;
     }
 
     .venture-disruptors-section h1 {
         font-size: 22px;
         text-align: center;
     }
 
     .venture-disruptors-section h1 span {
         font-size: 24px;
         text-align: center;
     }
  
     .venture-disruptors-section .brands-logo {
         display: grid;
         grid-template-columns: 50% 50%;
         justify-content: space-between;
         align-items: center;
         gap: 30px 10px;
         width: 80%;
         margin: auto;
     }

     .venture-disruptors-section .brands-logo div,
     .venture-disruptors-section .brands-logo img {
         width  : auto;
         height: auto;    
         margin-right: auto;
     }
 
     .venture-disruptors-section .brands-logo div:last-child img {
        height: 35px;
     }

     /* .venture-disruptors-section .brands-logo img.small {
         max-height: 75px;
         width: auto;
         margin: auto;
     }
 
     .venture-disruptors-section .brands-logo img.small10,
     .venture-disruptors-section .brands-logo img.small12,
     .venture-disruptors-section .brands-logo img.small13,
     .venture-disruptors-section .brands-logo img.small14,
     .venture-disruptors-section .brands-logo img.small15,
     .venture-disruptors-section .brands-logo img.small16 {
         width: auto;
     }
 
     .venture-disruptors-section .brands-logo img.small10 {
         max-height: 30px;
     }
 
     .venture-disruptors-section .brands-logo img.small12 {
         max-height: 40px;
     }
 
     
     .venture-disruptors-section .brands-logo img.small13 {
         max-height: 75px;
     }
 
     .venture-disruptors-section .brands-logo img.small15,
     .venture-disruptors-section .brands-logo img.small16 {
         max-height: 60px;
     } */
 
}


@media (min-width: 768px) and (max-width: 1024px) {
     
    .venture-disruptors-section {
        padding-top: 0px;
        padding-bottom: 50px;
    }

    .venture-disruptors-section h1 {
        font-size: 22px;
        text-align: center;
    }

    .venture-disruptors-section h1 span {
        font-size: 24px;
        text-align: center;
    }
 
    .venture-disruptors-section .tablet .brands-logo {
        display: grid;
        grid-template-columns: auto auto auto;
        justify-content: space-between;
        align-items: center;
        text-align: center;
        gap: 30px 10px;
        width: 75%;
        margin: auto;
    }

    .venture-disruptors-section .brands-logo img {
        width  : auto;
        height: auto;    
        margin-right: auto;
    }

    /* .venture-disruptors-section .brands-logo img.small {
        max-height: 75px;
        width: auto;
        margin: auto;
    }

    .venture-disruptors-section .brands-logo img.small10,
    .venture-disruptors-section .brands-logo img.small12,
    .venture-disruptors-section .brands-logo img.small13,
    .venture-disruptors-section .brands-logo img.small14,
    .venture-disruptors-section .brands-logo img.small15,
    .venture-disruptors-section .brands-logo img.small16 {
        width: auto;
    }

    .venture-disruptors-section .brands-logo img.small10 {
        max-height: 30px;
    }

    .venture-disruptors-section .brands-logo img.small12 {
        max-height: 40px;
    }

    
    .venture-disruptors-section .brands-logo img.small13 {
        max-height: 75px;
    }

    .venture-disruptors-section .brands-logo img.small15,
    .venture-disruptors-section .brands-logo img.small16 {
        max-height: 60px;
    } */

}