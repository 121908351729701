.wwerinnovators-section {
    padding: 100px 0 50px 0px;
}

.wwerinnovators-section h2 {
    color: #000000;
    font-family: Montserrat;
    font-size: 40px;
    font-style: normal;
    font-weight: 300;
    line-height: 150%; /* 60px */
}

.wwerinnovators-section h2 span {
    color: #1EC337;
    font-family: Lora;
    font-size: 40px;
    font-style: italic;
    font-weight: 400;
    line-height: 150%;
}

.wwerinnovators-section .bl-green .text-box p {
    color: #000000;
    font-family: Montserrat;
    font-size: 18px;
    font-weight: 400;
    line-height: 150%;
    text-align: left;    
}

.wwerinnovators-section .heading p {
    color: #000000;
    font-family: Montserrat;
    font-size: 22px;
    font-weight: 400;
    line-height: 33px;
    font-size: 18px;
    line-height: 150%;
    text-align: left;    
} 

.wwerinnovators-section .heading-box {
    border-left: 6px solid #1EEDA2;
    padding: 5px 10px;
    margin: 90px 0px 60px;

    color: #000;
    font-family: Montserrat;
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
}

.team {
  /* display: grid;
  grid-template-columns: auto auto auto;
  gap: 20px; */
  justify-content: center;
}

.team .member .member-wrapper {
    /* max-width: 264px; */
    width: 100%;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
}

/* .team .member:nth-child(3n+2) .member-wrapper {
    margin: auto;
}

.team .member:nth-child(3n+3) .member-wrapper {
    margin-left: auto;
}

.team.venture-team .member:last-child .member-wrapper,
.team.advisory-team .member:last-child .member-wrapper {
    margin: auto;
}

.team .offset-1.member .member-wrapper {
    margin-right: auto;
    margin-left: unset;
}
 */

.team .member .img-box img {
    width: 100%;
}

.team .member .text-box {
    padding: 20px 20px 10px 0;
}

.team .member .text-box h3 {
    color: #000;
    font-family: Montserrat;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 33px */
}

.team .member .text-box p {
    color: #000;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 27px */
}

.team .member .additional .showbio,
.team .member .additional .viewlinkedin {
    color: #16D691;
    font-family: Montserrat;
    font-size: 16.272px;
    font-style: normal;
    font-weight: 500;
    line-height: 14.238px; /* 87.5% */
    text-decoration-line: underline;
}



.team .member {
    position: relative;
}


/* 
.team .member .additional .bio {
    padding: 30px 30px 50px;
    border: 1px solid #ffffff;
    box-shadow: 0px 24px 60px 2px #00000033;
    z-index: 1;
    position: absolute;
    background: #ffffff;
    width: 100%;
    min-width: 60vw;
    bottom: 100px;
    visibility: hidden;
}

.team .member:nth-child(3n+2) .additional .bio {
    left: -80%;
}

.team .member:nth-child(3n+3) .additional .bio {
    left: -150%;
} */


.team .member .additional .bio {
    padding: 30px 30px 50px;
    border: 1px solid #ffffff;
    box-shadow: 0px 24px 60px 2px #00000033;
    z-index: 5;
    position: fixed;
    background: #ffffff;
    width: 75%;
    min-width: 60%;
    /* bottom: 100px; */
    top: 50%;
    left: 50%;
    transform: translate(-50% ,-50%);
    visibility: hidden;
}

.additional  .bio img {
    width: 100%;
    border-radius: 100%;
}

.additional .showbio,
.additional .viewlinkedin,
.additional .bio .close {
    cursor: pointer;
}

.bio h3 { 
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
}

.bio p {
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
 
}

@media screen and (max-width: 768px) { 

    .wwerinnovators-section {
        padding: 70px 0 0 0;
    }

    .wwerinnovators-section .container {
        padding: 0px 25px;
    }


    .wwerinnovators-section h2 {
        line-height: 150%; 
        font-size: 22px;
    }
    
    .wwerinnovators-section h2 span {
        font-size: 24px;
        line-height: 150%;
    }
    
    .wwerinnovators-section .bl-green .text-box p {
        color: #000000;
        font-family: Montserrat;
        font-size: 18px;
        line-height: 27px;
        text-align: left;    
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
    }
    
    .wwerinnovators-section .heading p {
        color: #000000;
        font-family: Montserrat;
        font-size: 18px;
        font-weight: 400;
        line-height: 27px;
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
    } 

    .wwerinnovators-section .heading-box {
        font-size: 18px;
        font-weight: 600;
        line-height: 27px;
        letter-spacing: 0em;
        margin:45px 0px 30px;
    }
    
    .team  .member.col-md-4  {
        width: 50%;
    }  

    .team .member .member-wrapper {
        /* max-width: 264px; */
        width: 75%;
        margin: auto;
    }

    .team .member .text-box h3 {
        font-size: 14px;
        font-weight: 600;
        line-height: 21px;
        letter-spacing: 0em;
        text-align: left;
    }

    .team .member .text-box p {
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
        letter-spacing: 0em;
    }

    .team .member .additional .showbio,
    .team .member .additional .viewlinkedin {
        font-size: 10px;
        font-weight: 500;
        line-height: 6px;
        letter-spacing: 0em;
    }

    .team  .member.col-md-4.offset-1 {
        margin-left: 0px;
    }

    .team .member .additional .bio {
        width: 80%;
        height: 75%;
        overflow-y: scroll;
    }

    .bio .bio-header-mb {
        display: grid;
        grid-template-columns: 25% 75%;
        gap: 15px;
    }

    .bio .bio-header-mb h3 {
        font-size: 14px;
        font-weight: 600;
        line-height: 21px;
        letter-spacing: 0em;
        text-align: left;
    }

    .bio .bio-header-mb p {    
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
        letter-spacing: 0em;
        text-align: left;
    }

    .bio .des p {
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
        letter-spacing: 0em;
        text-align: justify;
    }

}

.team .member .additional .showbio.cheight {
    height: 40px;
}

@media (min-width: 768px) and (max-width: 1024px) { 
    .member.col-md-3.offset-1, .member.col-md-3 {
        width: 33%;
        margin-left: 0px;
    }

    


    .wwerinnovators-section h2 {
        line-height: 150%; 
        font-size: 22px;
    }
    
    .wwerinnovators-section h2 span {
        font-size: 24px;
        line-height: 150%;
    }
    
    .wwerinnovators-section .bl-green .text-box p {
        color: #000000;
        font-family: Montserrat;
        font-size: 18px;
        font-weight: 400;
        line-height: 27px;
        text-align: left;    
    }
    
    .wwerinnovators-section .heading p {
        color: #000000;
        font-family: Montserrat;
        font-size: 18px;
        font-weight: 400;
        line-height: 27px;
    } 

    .wwerinnovators-section .heading-box {
        font-size: 18px;
        font-weight: 600;
        line-height: 27px;
        letter-spacing: 0em;
        margin:45px 0px 30px;
    }
    
    .team  .member.col-md-4  {
        width: 50%;
    }  

    .team .member .member-wrapper {
        /* max-width: 264px; */
        width: 75%;
        margin: auto;
    }

    .team .member .text-box h3 {
        font-size: 14px;
        font-weight: 600;
        line-height: 21px;
        letter-spacing: 0em;
        text-align: left;
    }

    .team .member .text-box p {
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
        letter-spacing: 0em;
    }

    .team .member .additional .showbio,
    .team .member .additional .viewlinkedin {
        font-size: 10px;
        font-weight: 500;
        line-height: 6px;
        letter-spacing: 0em;
    }

    .team .member .additional .showbio.cheight {
        height: 20px;
    }

    .team  .member.col-md-4.offset-1 {
        margin-left: 0px;
    }

    .team .member .additional .bio {
        width: 80%;
        height: 75%;
        overflow-y: scroll;
    }

    .bio .bio-header-mb {
        display: grid;
        grid-template-columns: 25% 75%;
        gap: 15px;
    }

    .bio .bio-header-mb h3 {
        font-size: 14px;
        font-weight: 600;
        line-height: 21px;
        letter-spacing: 0em;
        text-align: left;
    }

    .bio .bio-header-mb p {    
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
        letter-spacing: 0em;
        text-align: left;
    }

    .bio .des p {
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
        letter-spacing: 0em;
        text-align: justify;
    }

}