*, body {
    scroll-behavior: smooth;
}

.App {
    overflow-x: hidden;
    scroll-behavior: smooth;
}

.blank {
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    background: white;
    z-index: 20;
  }  

.main-btn {
    color: #000;
    font-family: Montserrat;
    font-size: 16.272px;
    font-style: normal;
    font-weight: 500;
    line-height: 87.5%; 
    margin-top: 20px;
    display: inline-flex;
    padding: 19.323px 30.511px;
    justify-content: center;
    align-items: center;
    gap: 10.17px;
    border-radius: 5px;
    background: #1EEDA2;
    text-decoration: none;
}

.ps--5 {
    margin-left: -3rem !important;
}

.mobile, .mobile-inline, .mobile-inline-flex ,
.tablet, .tablet-inline, .tablet-inline-flex {
    display:none;
}

.desktop {
    display:block;
}

.desktop-inline {
    display: inline-block;
}

.desktop-inline-flex {
    display: inline-flex;
}


p.form-error {
    color: red;
}

@media screen and (max-width: 767px) {
    .order-m-0 {
        order: 0;
    }

    .order-m-1 {
        order: 1;
    }

    .tablet,
    .tablet-inline,
    .tablet-inline-flex {
        display: none;
    }

    .desktop,
    .desktop-inline,
    .desktop-inline-flex {
        display:none;
    }
    
    .mobile {
        display:block;
    }

    
    .mobile-inline {
        display: inline-block;
    }

    .mobile-inline-flex {
        display: inline-flex;
    }

    .bl-green {
        padding-left: 3rem !important;
        padding-right: 3rem !important;
    }

    .bl-green:before {
        left: 18px !important;
    }

    .bl-green .text-box::before {
        width: 6px !important;
    }
 
}

@media (min-width: 768px) and (max-width: 1024px) { 

    .desktop,
    .mobile,
    .mobile-inline,
    .desktop-inline,
    .desktop-inline-flex,
    .mobile-inline-flex {
        display:none;
    }
    
    .tablet {
        display:block;
    }

    .tablet-inline {
        display: inline-block;
    }

    .tablet-inline-flex {
        display: inline-flex;
    }

}


@media (orientation: landscape) {
    .ps--5 {
        margin-left: 0px !important;
    }
}