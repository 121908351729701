.wweraifirst-section {
    padding: 100px 0px;
}

.wweraifirst-section .aifirt-bg {
    position: relative;
    width: 100%;
    height: auto;
    padding: 50px 0px;
}

.wweraifirst-section .aifirt-bg video.background-video {
    width: 100%;
    position: absolute;
    object-fit: cover;
    left: 0;
    top: 0;
    height: 100%;
    z-index: -1;
}

.wweraifirst-section .main-box {
    display: flex;
    flex-direction: column;
    gap: 36px;
}

.wweraifirst-section .main-box h1 {
    color: #000;
    text-align: center;
    font-family: Montserrat;
    font-size: 40px;
    font-style: normal;
    font-weight: 300;
    line-height: 150%; /* 60px */
}

.wweraifirst-section .main-box h1 span {
    color: #1EC337;
    font-family: Lora;
    font-size: 40px;
    font-style: italic;
    font-weight: 400;
    line-height: 150%;
}

.wweraifirst-section  .aifirt-bg p {
    margin-top: 30px;
    color: #1EC337;
    text-align: center;
    font-family: Lora;
    font-size: 20px;
    font-style: italic;
    font-weight: 700;
    line-height: 150%;
}


.wweraifirst-section .aifirt-bg .img-box {
    /* background: url('../images/wwer-imgbg.webp'); */
    background: #ffffffb5;
    padding: 30px 50px;
    max-width: 80%;
    margin:auto 50px auto auto;
    margin:auto;
}

.wweraifirst-section .aifirt-bg .img-box  p {
    color: #000;
    text-align: justify;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; 
}


/* wwerrooted-section */
.wwerrooted-section {
    padding-top: 1rem!important;
    padding-bottom: 0rem!important    
}

.wwerrooted-section .row {
    justify-content: center;
    align-items: center;
    padding-top: 1.5em;
    padding-bottom: 1.5em;
}

.text-right {
    text-align: right !important;
}

.wwerrooted-section .bl-green h2 {
    color: #1A202C;
    font-family: Montserrat;
    font-size: 40px;
    font-style: normal;
    font-weight: 300;
    line-height: 150%; 
}

.wwerrooted-section .text-box h2 span { 
    color: #1EC337;
    font-family: Lora;
    font-size: 40px;
    font-style: italic;
    font-weight: 400;
    line-height: 150%;
}

.bl-green {
    position: relative;
    padding-top: 2em;
    padding-bottom: 2em;
}

.text-box {
    position: relative;
}

.bl-green:before {
    content: "";
    width: 1px;
    height: 100%;
    position: absolute;
    background: #1EEDA2;
    top: 0%;
    left: -30px;
}


.bl-green .text-box::before {
    content: "";
    width: 11px;
    height: 100%;
    position: absolute;
    background: #1EEDA2;
    top: 0%;
    left: -30px;
}

.wwerrooted-section h3 {
    color: #000;
    font-family: Montserrat;
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
}

.wwerrooted-section p {
    color: #000;
    font-family: Montserrat;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; 
}


@media screen and (max-width: 768px) { 
    .wweraifirst-section {
        padding: 50px 0px;
    } 

    .wweraifirst-section .main-box h1 {
        font-size: 22px;
        font-weight: 300;
        line-height: 33px;
        letter-spacing: 0em;
        text-align: center;
    }
    
    .wweraifirst-section .main-box h1 span {
        font-size: 24px;
        font-style: italic;
        font-weight: 400;
        line-height: 36px;
        letter-spacing: 0em;
        text-align: center;
    }

    .wweraifirst-section .aifirt-bg .img-box {
        max-width: 85%;
        padding: 10px;
        margin: auto;
    }

    .wweraifirst-section .aifirt-bg .img-box p {
        text-align: center;
    }
    .wweraifirst-section .aifirt-bg p {
        font-size: 14px;
        font-weight: 700;
        line-height: 21px;
        letter-spacing: 0em;
        text-align: center;

    }

    .wweraifirst-section p {
        font-size: 14px;
        line-height: 21px;
        letter-spacing: 0em;
        text-align: center;
        padding: 0 5%;
    }

    /* wwerrooted-section */
    .wwerrooted-section {
        padding-top: 50px !important;
        padding-bottom: 0px !important;    
    }

    .wwerrooted-section .bl-green h2 {
        font-size: 22px;
        font-weight: 300;
        line-height: 33px;
        letter-spacing: 0em;
    }

    .wwerrooted-section .text-box h2 span {
        font-size: 24px;
        font-weight: 400;
        line-height: 36px;
        letter-spacing: 0em;
    }

    .wwerrooted-section .col-md-6 {
        padding-left: 3rem !important;
        padding-right: 3rem !important;
        text-align: center;
    }

    .wwerrooted-section .col-md-6 img {
        width: 65%;
        margin: auto;
    }

    .wwerrooted-section h3 {
        font-family: Montserrat;
        font-size: 18px;
        font-weight: 500;
        line-height: 27px;
        letter-spacing: 0em;
    }

    .wwerrooted-section p {
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
        letter-spacing: 0em;
    }
}
