.beyondthedesk-section {
    padding: 100px 0px;
}

.beyondthedesk-section .main-box {
    display: flex;
    flex-direction: column;
    gap: 36px;
}

.beyondthedesk-section .main-box h1 {
    color: #000;
    text-align: center;
    font-family: Montserrat;
    font-size: 40px;
    font-style: normal;
    font-weight: 300;
    line-height: 150%; /* 60px */
}

.beyondthedesk-section .main-box h1 span {
    color: #1EC337;
    font-family: Lora;
    font-size: 40px;
    font-style: italic;
    font-weight: 400;
    line-height: 150%;
}


/* clouddriven-section */
.beyondthedesk-section {
    padding-top: 4rem!important;
    padding-bottom: 2rem!important    
}

.beyondthedesk-section .row {
    justify-content: center;
    align-items: center;
    padding-top: 1.5em;
    padding-bottom: 1.5em;
    padding-top: 0em;
    padding-bottom: 0em;
}

.beyondthedesk-section img {
    width: 100%;
}

.text-right {
    text-align: right !important;
}

.beyondthedesk-section .text-box h2 {
    color: #1A202C;
    font-family: Montserrat;
    font-size: 40px;
    font-style: normal;
    font-weight: 300;
    line-height: 150%; 
}

.beyondthedesk-section .text-box h2 span { 
    color: #1EC337;
    font-family: Lora;
    font-size: 40px;
    font-style: italic;
    font-weight: 400;
    line-height: 150%;
}

.bl-green {
    position: relative;
    padding-top: 2em;
    padding-bottom: 2em;
}

.text-box {
    position: relative;
}

.bl-green:before {
    content: "";
    width: 1px;
    height: 100%;
    position: absolute;
    background: #1EEDA2;
    top: 0%;
    left: -30px;
}


.bl-green .text-box::before {
    content: "";
    width: 11px;
    height: 100%;
    position: absolute;
    background: #1EEDA2;
    top: 0%;
    left: -30px;
}

.beyondthedesk-section h3 {
    color: #000;
    font-family: Montserrat;
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
}

.beyondthedesk-section p {
    color: #000;
    font-family: Montserrat;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; 
}

.beyondthedesk-section .img-box {
    overflow: hidden;
}
.beyondthedesk-section .img-box img {
    transition:all .5s ease-in;

}

/* .beyondthedesk-section .img-box img:hover {
    transform: scale(1.3);
} */

.beyondthedesk-section .text-side h3 {
    font-family: Montserrat;
    font-size: 22px;
    font-weight: 500;
    line-height: 33px;
    letter-spacing: 0em;
    text-align: left;
    color: #000000;
}

.beyondthedesk-section .text-side h4 {
    font-family: Lora;
    font-size: 20px;
    font-style: italic;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
    color: #1EC337;
}


.beyondthedesk-section .text-side p {
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
}

.beyondthedesk-section .text-side ul  {
    padding-left: 20px;
    list-style-image: url('../images/Ornament44.svg');    
}

.beyondthedesk-section .text-side ul li {
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: #000000  ;
}


.beyondthedesk-section .text-side ul li::before {
    content: ""; 
    width: 11.52px;
    height: 11.52px;
    top: 353.14px;
    left: 95.14px;
    color:#010103;
  }

  .beyondthedesk-section .text-side h5 {
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: #000000;
  }

  .beyondthedesk-section .text-side p.inline-list {
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 600;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
    color: #000000;    
  }

@media screen and (max-width: 767px) {
    
   /* .beyondthedesk-section .row {
     padding-top: 0em;
     padding-bottom: 0em;
   }   */

  .beyondthedesk-section .main-box h1 ,
  .beyondthedesk-section .main-box h1 span {
    font-size: 22px;
  }

  .beyondthedesk-section img {
    width:75%;
  }

  .beyondthedesk-section .text-side.pe-5 {
    padding-right: 0px !important;
  } 

  .beyondthedesk-section .text-side h3 {
    font-size: 18px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: center;
  }

  .beyondthedesk-section .text-side p {
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: center;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
    
    /* .beyondthedesk-section .row {
      padding-top: 0em;
      padding-bottom: 0em;
    }   */
 
   .beyondthedesk-section .main-box h1 ,
   .beyondthedesk-section .main-box h1 span {
     font-size: 22px;
   }
 
   .beyondthedesk-section img {
     width:75%;
   }
 
   .beyondthedesk-section .text-side.pe-5 {
     padding-right: 0px !important;
   } 
 
   .beyondthedesk-section .text-side h3 {
     font-size: 18px;
     font-weight: 600;
     line-height: 22px;
     letter-spacing: 0em;
     text-align: left;
   }
 
   .beyondthedesk-section .text-side p {
     font-size: 12px;
     font-weight: 400;
     line-height: 18px;
     letter-spacing: 0em;
     text-align: left;
   }
 }
